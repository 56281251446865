.tutorial {
  height: 20%;
  color: white;
  z-index: 50000;
  position: absolute;
  top: 40%;
  bottom: 10%;
  left: -1vh;
  background: goldenrod;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 2vh;
  border-radius: 1vh;
  cursor: pointer;
  width: 5%;
}

.tutorial h3 {
  transform: rotate(-90deg);
}
