.scoreboard {
  font-family: "Rubik", sans-serif;
  font-size: 5vh;
  font-weight: 600;
  color: #f5e5c6;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 2vh;

  &__name {
    font-size: 4vh;
  }
  &__score {
    margin-bottom: 1vh;
  }

  &__doubloons {
    img {
      width: 4.5vh;
      height: 4.5vh;
      margin-right: -2vh;
    }
    #left {
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
  }
}

.game-scoreboard .mini-token {
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  display: inline-block;
  background-color: #78909c;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 45% 50%;
  vertical-align: middle;
  cursor: pointer;
}
.game-scoreboard h3 {
  margin: 0vh;
  padding: 0vh;
}
.game-scoreboard .tab {
  margin-left: 2vw;
}
.game-scoreboard .cross-small {
  font-size: 2vh;
}
.mini-token:hover {
  background-color: #4a626d;
}
