.card-slot {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 2.5vh;
  border: 2px dashed #f7e4c1;
  text-align: center;
}

.card-slot.card:hover {
  cursor: default;
}
