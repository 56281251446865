.player-section {
  display: flex;
  align-items: center;
}

.board-section__top {
  .player-section {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.board-section__bottom {
  .player-section {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.player-section.choosable {
  border: 1px solid rgba(246, 208, 112, 0.7);
  cursor: pointer;
  animation: pulse-gold 1.2s infinite;
}

@keyframes pulse-gold {
  0% {
    box-shadow: 0 0 0 0 rgba(246, 208, 112, 0.7);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(246, 208, 112, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(246, 208, 112, 0);
  }
}
