.sidebar {
  height: 80%;
  z-index: 50000;
  position: absolute;
  top: 10%;
  bottom: 10%;
  right: -1vh;
  transition: width 0.4s;
}
.sidebar.open {
  width: 16vw;
}
.sidebar.closed {
  width: 4vw;
}
.sidebar.closed .sidebar-tab {
  width: 100%;
}
.sidebar.closed .sidebar-content {
  width: 0%;
}
.sidebar.open .sidebar-tab {
  width: 16%;
}
.sidebar.open .sidebar-content {
  width: 84%;
}
.sidebar-tab {
  display: inline;
  float: left;
  height: 100%;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  position: relative;
  transition: width 0.4s;
}
.sidebar-content {
  display: inline-flex;
  float: left;
  height: 100%;
  /* background: rgba(203, 186, 131, 0.7); */
  background: rgba(255, 255, 255, 0.6);
  transition: width 0.4s;
  border-radius: 1vh;
}
.icon-container {
  margin: 0;
  z-index: -1;
  /* border: solid; */
  border-radius: 1vh;
  height: 20%;
  position: absolute;
  top: 40%;
  right: -10%;
  bottom: 40%;
  width: 100%;
  background: goldenrod;
  display: flex;
  font-size: 2vh;
  justify-content: center;
  align-items: center;
}
.icon-container h3 {
  /*   writing-mode: vertical-rl;
    margin-left: 2px;
    text-align: center;
    vertical-align:center; */
  transform: rotate(-90deg);
}
.chatbox {
  height: 100%;
  overflow: auto;
  margin-right: 1.5vh;
  font-size: 1.6vh;
}
.chat-item {
  margin: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #4a1b14;
}

.chat-item.header {
  text-align: center;
  font-weight: bold;
}

.chat-item.not-me {
  text-align: right;
}
.chat-item.me {
  text-align: left;
}

.discord-button {
  height: 20%;
  color: white;
  z-index: 90000;
  position: absolute;
  top: 20%;
  bottom: 10%;
  left: -1vh;
  background: goldenrod;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 2vh;
  border-radius: 1vh;
  cursor: pointer;
  width: 5%;
}

.discord-button h3 {
  transform: rotate(-90deg);
  white-space: nowrap;
}
