.deep-button {
  font-family: Eczar;
  background-color: goldenrod;
  height: 4vh;
  font-size: 2vh;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: -0px 8px 0px 0px #4a1b14;
  margin: 8px;
  padding: 2.2vh;
  padding-left: 5vh;
  padding-right: 5vh;
}
.deep-button:active {
  box-shadow: -0px 2px 0px 0px #4a1b14;
  transform: translateY(4px) translateX(-1px);
}
.deep-button:disabled {
  background-color: #f7e4c1;
  color: #4a1b14;
  box-shadow: -0px 2px 0px 0px #4a1b14;
  transform: translateY(4px) translateX(-1px);
  cursor: not-allowed;
}

.deep-button.trade {
  background-color: #f9a825;
}
.deep-button.trade:disabled {
  background-color: #4a1b14;
}

.header-nav-button {
  background-color: goldenrod;
  color: white;
  float: left;
  font-family: Eczar;
}

.play-again-button {
  font-family: Eczar;
  background-color: goldenrod;
  height: 3vh;
  font-size: 3vh;
  color: white;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: -0px 8px 0px 0px #4a1b14;
  margin: 5px;
  padding: 2vh;
  padding-left: 5vh;
  padding-right: 5vh;
}
