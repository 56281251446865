.help-content {
  max-width: 60%;
  margin: auto;
  text-align: left;
}

a:link {
  color: goldenrod;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: goldenrod;
  background-color: transparent;
  text-decoration: none;
}
