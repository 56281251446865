.Home {
  text-align: center;
  padding-top: 100px;
}

.Home-logo {
  height: 30vmin;
  pointer-events: none;
}

.Home-header {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.count {
  font-family: Eczar;
  padding: 10px 25px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  outline: none;
  color: #daa520;
}

.wordmark {
  display: inline-block;
  width: 500px;
  height: 40vmin;
  object-fit: contain;
  background-image: url("../../assets/wordmark.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Home-button {
  font-family: Eczar;
  padding: 10px 25px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 300px;
  cursor: pointer;
  outline: none;
  color: #4a1b14;
  background-color: #f7e4c1;
  border: 8px solid #daa520;
  border-radius: 15px;
  box-shadow: 0 9px #000;
}

.Home-button:hover {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.Home-button:active {
  box-shadow: 0 5px #000;
  transform: translateY(4px);
}
