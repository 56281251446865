@keyframes pulsate {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3, 1.3);
    opacity: 0.1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

/**
    END Component Animations
**/

// DEFINE VARIABLES
$small-gap: 0.5vh;
$med-gap: 1vh;
$card-width: 10vh;
$card-height: $card-width * 1.5;
$card-break-gap: 1.5 * $card-width;
$card-right-offset: -$card-width + ($card-width * 0.3);
$std-border-radius: 1vh;

.card-container {
  display: flex;
  padding: $small-gap;
  height: $card-height * 1.2;
  position: relative;

  &__slots {
    //  calculation is $card-width + 2 * $small-gap -- i can't get calc to work for some reason
    left: 11.5vh;
    position: absolute;
  }
  &__discard {
    left: ($card-width + $med-gap) * 5;
    position: absolute;
  }
  &__discard-name {
    margin-top: -0.2vh;
    font-family: "Rubik", sans-serif;
    color: #f7e4c1;
    font-weight: 600;
    font-size: 1.8vh;
    text-align: center;
  }
}

.card-container.overlap {
  .card {
    margin-right: ($card-right-offset/1.5);
  }
  margin-right: -$card-right-offset;
}

.explanation {
  padding: 5px;
  position: absolute;
  top: calc(-60px);
  left: -$card-width;
  right: 0;
  background-color: gray;
  font-size: 15px;
  width: 200px;
  min-height: 50px;
  transform: scale(0.5);
  border-radius: 4px;
  border: 2px solid darkgray;
  box-shadow: inset 0 5px 5px hsla(0, 0%, 0%, 0.4);
  color: white;
}

.card-button {
  position: absolute;
  top: $card-height / 8;

  font-family: Eczar;
  background-color: goldenrod;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: -0px 2px 0px 0px #4a1b14;
  z-index: 11;
}

.card {
  width: $card-width;
  height: $card-height;
  border-radius: $std-border-radius;
  border-color: transparent;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: $med-gap $small-gap;
  color: #f7e4c1;
  transition: margin-top 0.3s;
  cursor: pointer;
  position: relative;

  &:after {
    // uncomment styles below to see hit areas
    // background: rgba(255, 0, 0, 0.15);
    // border: 1px solid cyan;
    bottom: 0;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    z-index: 10;
  }

  .explanation {
    display: none;
  }
}

.card:hover {
  .explanation {
    display: unset;
  }
}

// shamelessly stolen from https://codepen.io/jackrugile/pen/WZGeGM?editors=1100
$total: 20;
@for $i from 0 through ($total - 1) {
  $hue: ($i / $total) * -360;
  $rotationRange: 50;
  $rotation: ($i - ($total - 1) / 2) / ($total - 2) * $rotationRange;
  $offsetRange: 80;
  $offset: abs(($i - ($total - 1) / 2) / ($total - 2) * $offsetRange);
  .card:nth-child(#{$i + 1}) {
    .card-inside {
      box-shadow: -2px 2px 2px hsla(0, 0%, 0%, 0.15);
    }
    &:hover {
      .card-inside {
        transform: scale(2);
        transition-duration: 0ms;
        z-index: 5;
        &:after {
          animation: fade 250ms ease-out forwards;
        }
      }
      &:after {
      }
    }
  }
}

.board-section__bottom {
  .card:hover {
    .card-inside {
      transform: translateY(-$card-height/2) scale(2);
    }
  }
}

.board-section__top {
  .card:hover {
    .card-inside {
      transform: translateY($card-height/2) scale(2);
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0.9;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.15);
  }
}

.card-deck {
  border-bottom-right-radius: $std-border-radius;
  border-bottom-left-radius: $std-border-radius;

  .action {
    box-shadow: 0px 10px 0px #eab53f !important;
  }
  .pirate {
    box-shadow: 0px 10px 0px #783f3a !important;
  }

  .card,
  &:hover {
    .card-inside {
      transform: scale(1) !important;
    }
  }
}

.card-deck__discard {
  left: ($card-width + $med-gap) * 5;
  position: absolute;
}

.card-inside.action {
  transform: scale(1) !important;
}

.card.selected {
  margin-top: 0px;
  color: #43a047;
}
.card.selected:hover {
  color: lime;
}

.card.not-playable {
  .card-inside {
    // border: 2px solid transparent;
    cursor: not-allowed;
  }
}

.card.clickable {
  border: 2px solid transparent;
}

.card.playable {
  .card-inside {
    border: 2px solid greenyellow;
    box-shadow: 0 0 5px greenyellow;
  }
}

.card.choosable {
  .card-inside {
    border: 2px solid #ad00fe;
    box-shadow: 0 0 6px #ad00fe;
  }
}

/** Card representing the deck **/
.deck.card {
  margin-right: $card-break-gap;
  box-shadow: -4px 4px 0px 1px #4a1b14;
}

/** Container holding a bunch of cards **/
.card-container .card.clickable:hover,
.card-container .card.playable:hover,
.card-container .card.choosable:hover {
  margin-top: 0px;
}
.card-container .card.deck:hover {
  margin-top: $med-gap * 0.6;
}

/** FONT **/
h3 {
  font-family: "Eczar";
}

/** The inner portion of the Card containing
    the resource colors
**/

.card-cost {
  position: absolute;
  margin-top: -25px;
  top: calc(0.35 * #{$card-width});
  left: calc(0.05 * #{$card-width});
  width: calc(0.25 * #{$card-width});
  height: calc(0.25 * #{$card-width});
  font-size: 1.5vh;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  color: #4a1b14;
  background-color: #f6d070;
  border: 2px solid darkgoldenrod;
  box-shadow: -2px 2px 2px hsla(0, 0%, 0%, 0.15);
}

.card-disabled {
  filter: grayscale(100%) brightness(40%) sepia(90%) hue-rotate(-50deg)
    saturate(600%) contrast(0.8);
}

.card-played {
  filter: grayscale(100%);
}

.card-inside {
  width: 100%;
  height: 100%;
  border-radius: $std-border-radius * 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/** CARD BACKS **/
.card-inside.pirate {
  background-image: url("../../assets/backs/pirateback.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.action {
  background-image: url("../../assets/backs/actionback.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/** ACTION CARDS **/
.card-inside.scallywag {
  background-image: url("../../assets/actions/scallywag.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.commandeer {
  background-image: url("../../assets/actions/commandeer.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.fireinthehole {
  background-image: url("../../assets/actions/fireinthehole.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.marooned {
  background-image: url("../../assets/actions/marooned.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.mehearties {
  background-image: url("../../assets/actions/mehearties.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.mutiny {
  background-image: url("../../assets/actions/mutiny.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.spyglass {
  background-image: url("../../assets/actions/spyglass.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.walktheplank {
  background-image: url("../../assets/actions/walktheplank.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.xmarksthespot {
  background-image: url("../../assets/actions/xmarksthespot.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.pillage {
  background-image: url("../../assets/actions/pillage.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.scallywag {
  background-image: url("../../assets/actions/scallywag.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.yohoho {
  background-image: url("../../assets/actions/yohoho.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.coffer {
  background-image: url("../../assets/actions/coffer.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.barter {
  background-image: url("../../assets/actions/barter.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.card-inside.plunder {
  background-image: url("../../assets/actions/plunder.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/** PIRATE CARDS **/
.card-inside.annebonny {
  background-image: url("../../assets/pirates/annebonny.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.barbarossa {
  background-image: url("../../assets/pirates/barbarossa.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.bartholomewroberts {
  background-image: url("../../assets/pirates/bartholomewroberts.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.blackbeard {
  background-image: url("../../assets/pirates/blackbeard.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.blackcaesar {
  background-image: url("../../assets/pirates/blackcaesar.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.charlottebadger {
  background-image: url("../../assets/pirates/charlottebadger.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.cheungpotsai {
  background-image: url("../../assets/pirates/cheungpotsai.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.chingshih {
  background-image: url("../../assets/pirates/chingshih.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.francisfernando {
  background-image: url("../../assets/pirates/francisfernando.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.graceomalley {
  background-image: url("../../assets/pirates/graceomalley.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.hendrickquintor {
  background-image: url("../../assets/pirates/hendrickquintor.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.henrymorgan {
  background-image: url("../../assets/pirates/henrymorgan.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.kanhojiangre {
  background-image: url("../../assets/pirates/kanhojiangre.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.maryread {
  background-image: url("../../assets/pirates/maryread.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.rachelwall {
  background-image: url("../../assets/pirates/rachelwall.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.samuelbellamy {
  background-image: url("../../assets/pirates/samuelbellamy.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.sayyidaalhurra {
  background-image: url("../../assets/pirates/sayyidaalhurra.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.card-inside.williamkid {
  background-image: url("../../assets/pirates/williamkidd.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
