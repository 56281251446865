.board {
  .board-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &__middle {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .board-prompt {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vh;
    min-width: 43vh;
    height: 2.6vh;
    font-weight: bold;
    color: #4a1b14;
    background-color: #f7e4c1;
    padding: 8px;
    border-radius: 8px;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.board-table {
  padding: 2vh;
  margin-bottom: 1vh;
}

// Win modal

.winmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  color: #f5e5c6;
  background: #4a1b14;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  min-width: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__doubloons {
    img {
      width: 4vh;
      height: 4vh;
      margin-right: -2vh;
    }
    #left {
      margin-right: 10px;
    }
    padding-left: 8px;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
  }

  &__outcome {
    font-weight: 550;
    text-align: center;
    font-size: 6vh;
  }

  &__scoreboard {
    font-size: 4vh;
    font-weight: 500;
    text-align: center;
    display: flex;
    flex-direction: row;
  }

  &__tabletoplink {
    display: "table-cell";
    font-size: 3vh;
  }
}

.winoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  z-index: 1000;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

// Settings modal

.settingsmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  color: #ffffff;
  background: #4a1b14;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.settingsoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

// Old page classes
.vsplit {
  height: 100%;
  position: fixed;
  padding: 6px;
}
.hsplit {
  width: 100%;
  position: relative;
}
.top {
  height: 50%;
  /* border: solid; */
  top: 0;
  /*   background: yellow; */
}
.middle {
  height: 50%;
  top: 0;
}
.bottom {
  height: 50%;
  /* border: solid; */
  top: 0;
  /*   background: green; */
}
.left {
  width: 43%;
  /* border: solid; */
  left: 0;
  /*   background: blue; */
}
.right {
  /* border: solid; */
  right: 0;
  /*   background: red; */
  width: 57%;
}

.turn-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}
