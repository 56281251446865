.Config-title {
  color: #f7e4c1;
}

.Config-content {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.Config-input {
  font-family: Eczar;
  padding: 10px 25px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 300px;
  outline: none;
  color: #4a1b14;
  background-color: #f7e4c1;
  border: 8px solid goldenrod;
  border-radius: 15px;
  box-shadow: 0 9px #000;
}

.Config-button {
  font-family: Eczar;
  padding: 10px 25px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 300px;
  cursor: pointer;
  outline: none;
  color: #4a1b14;
  background-color: #f7e4c1;
  border: 8px solid #daa520;
  border-radius: 15px;
  box-shadow: 0 9px #000;
}

.Config-button:hover {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.Config-button:active {
  box-shadow: 0 5px #000;
  transform: translateY(4px);
}
