.Game {
  text-align: center;
  padding-top: 100px;
}

.Game-logo {
  height: 15vmin;
  pointer-events: none;
}

.Game-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.wordmark {
  display: inline-block;
  width: 500px;
  height: 40vmin;
  object-fit: contain;
  background-image: url("../assets/wordmark.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Game-button {
  font-family: Eczar;
  padding: 10px 25px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 300px;
  cursor: pointer;
  outline: none;
  color: #4a1b14;
  background-color: #f7e4c1;
  border: 8px solid #daa520;
  border-radius: 15px;
  box-shadow: 0 9px #000;
}

.Game-button:hover {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.Game-button:active {
  box-shadow: 0 5px #000;
  transform: translateY(4px);
}

.game-link div {
  display: inline-block;
  padding: 16px;
}
.game-link {
  padding: 15px 30px 30px 30px;
  display: inline-flex;
  align-items: center;
}
.game-code {
  margin: 18px;
  font-size: 30px;
  color: white;
}
.game-link-box {
  border: solid;
  border-radius: 8px;
  margin: 8px;
  border-color: #757575;
  background-color: #cbba83;
}
.game-link-button {
  font-family: Eczar;
  background-color: goldenrod;
  font-size: 2vmin;
  color: white;
  border-radius: 8px;
  max-width: 200px;
  padding: 12px 20px;
  /* margin-bottom: 8px; */
  cursor: pointer;
  box-shadow: -0px 8px 0px 0px #4a1b14;
}
.game-link-button:hover {
  background-color: goldenrod;
}

.game-link-button:active {
  box-shadow: -0px 2px 0px 0px #4a1b14;
  transform: translateY(4px) translateX(-1px);
}

.join-game-button {
  font-family: Eczar;
  background-color: goldenrod;
  font-size: 2vmin;
  color: white;
  border-radius: 8px;
  max-width: 200px;
  padding: 10px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: -0px 8px 0px 0px #4a1b14;
}

.player-item {
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  border: none;
}
.player-item-edit:hover {
  color: white;
}
.player-ready {
  height: 4px;
  width: 100%;
  margin: auto;
  background-color: #66bb6a;
}

@keyframes pulse {
  0% {
    width: 30%;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 30%;
  }
}
