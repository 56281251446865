.Header {
  display: grid;
  grid-template-areas: "logo nav";
  position: fixed;
  top: 0; /* Stick it to the top */
  max-height: 70px;
  width: 100vw;
}

.Logo {
  grid-area: logo;
  height: 70px;
  margin: 10px;
}

.Nav {
  font-size: 20px;
  font-weight: bold;
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
}
.Nav a {
  font-size: 20px;
  font-weight: bold;
  transition: 0.5s;
  text-decoration: none;
}
.Nav a:hover {
  transform: scale(1.1);
}
.Nav button {
  padding: 10px;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: 600;
  background-color: rgba(255, 0, 0, 0.5);
  box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  transition: 70ms;
}

.Nav button:active {
  transform: translateY(3px);
  box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

@media (max-width: 700px) {
  .Header {
    grid-template-areas: "logo" "nav nav";
  }

  .Nav {
    visibility: none;
  }
}
