.Name-title {
  color: #f7e4c1;
}

.Name-content {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.Name-input {
  font-family: Eczar;
  padding: 10px 25px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 300px;
  outline: none;
  color: #4a1b14;
  background-color: #f7e4c1;
  border: 8px solid goldenrod;
  border-radius: 15px;
  box-shadow: 0 9px #000;
}

.Name-button {
  font-family: Eczar;
  font-weight: bold;
  background-color: goldenrod;
  border: 1px solid goldenrod;
  padding: 10px 25px;
  min-width: 50px;
  min-height: 30px;
  font-size: 2vmin;
  color: white;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  box-shadow: -0px 8px 0px 0px #000;
}

.Name-button:hover {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.Name-button:active {
  box-shadow: 0 5px #000;
  transform: translateY(4px);
}
