html,
body {
  height: 100%;
  font-family: Eczar;
  margin: 0;
  color: white;
}
.full_height {
  height: 100%;
  text-align: center;
}
.title {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
}
.doubloons-logo {
  display: inline-flex;
  height: 100%;
  width: 70px;
  margin-bottom: -10px;
  background-image: url("../../assets/logo.png");
  background-size: cover;
  /*
  background-repeat: no-repeat;
  background-position: 50% 50%; */
}

.title-text {
  height: 100%;
  display: inline-flex;
  margin: auto;
  font-size: 5vh;
  padding: 12px;
  color: #ffffff;
  font-weight: bold;
}

.subtitle-text {
  height: 100%;
  display: inline-flex;
  margin: auto;
  font-size: 2vh;
  padding: 5px;
  color: #ffffff;
}
