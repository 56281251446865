body {
  height: 100%;
  font-family: Eczar;
  margin: 0;
  color: white;
}

.full_height {
  /* background: #4a1b14; */
  text-align: center;
  width: 70%;
  top: 50%;
  margin-right: -50%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
}
.title {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
  padding-top: 100px;
}

.mobile-body {
  font-size: 3vh;
  line-height: 7vh;
}

.doubloons-logo {
  display: inline-flex;
  height: 100%;
  width: 70px;
  margin-bottom: -10px;
  background-image: url("../../assets/logo.png");
  background-size: cover;
}

.mobile-title {
  height: 100%;
  display: inline-flex;
  font-size: 5vh;
  margin-bottom: 2vh;
  padding: 12px;
  font-weight: bold;
}
